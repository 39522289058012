import { Link } from "react-router-dom";
import { Button, Result } from "antd";
const Page404 = () => (
    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        style={{ backgroundColor: "white" }}
        extra={<Button type="primary"><Link to="/">Back Home</Link></Button>}
    />
);
export default Page404;
