import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Typography } from "antd";
import HeroCarousel from "../global/carousel/heroCarousel";
import "./book.css";
import "../home/a.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { PlusOutlined,ShareAltOutlined, DownloadOutlined, ReadOutlined } from "@ant-design/icons";
import { Button, Space, Tooltip } from "antd";

const { Title } = Typography;

export default function Book() {
    const title = useParams();

    const [booksData, setBooksdata] = useState();

    useEffect(() => {
        //let isMounted = true;

        let config = {
            method: "get",
            url: `https://zenith-shorthaired-nape.glitch.me/books/${title.bookId}`, //https://zenith-shorthaired-nape.glitch.me/books/${title.bookId} // http://localhost:5000/books/${title.bookId}
            headers: {},
        };

        axios(config)
            .then(async (response) => {
                await setBooksdata(response.data.book);
            })
            .catch((error) => {
                console.log(error);
            });

        // return () => {
        //     isMounted = false;
        // };
    }, [title]);

    return (
        <div className="book-font-family">
            <Card
                style={{
                    borderRadius: "20px",
                }}
            >
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={false}
                    navigation={false}
                    className="mySwiper"
                    style={{ background: "#121926" }}
                >
                    <SwiperSlide
                        style={{ background: "#121926" }}
                        className="swiper-size"
                    >
                        <div
                            className="img-text-wrapper"
                            style={{ width: "95%" }}
                        >
                            <div className="logo-wrapper">
                                <img
                                    src="https://lumiere-a.akamaihd.net/v1/images/cover-2000-x-835_1_e15da45d.png?region=0,0,2000,835&width=1536"
                                    alt="Disney1"
                                    style={{
                                        objectFit: "cover !important",
                                        height: "100%",
                                    }}
                                ></img>
                            </div>
                            <div className="subtitle">
                                <div class="content-holder">
                                    <div
                                        class="title1 ellipsize screen-size1"
                                    >
                                        {booksData && booksData.name}{" "}
                                    </div>
                                    <div class="toptitle">
                                        <span class="meta-data screen-size2">
                                            Thriller
                                        </span>
                                        <span class="meta-data">.</span>
                                        <span class="meta-data screen-size2">
                                            2022
                                        </span>
                                    </div>
                                    <div class="description title1 ellipsize screen-size3">
                                        {" "}
                                        When a tiger named Shere Khan threatens
                                        to kill Mowgli, a young boy raised by
                                        wolves, his friends Bagheera and Baloo
                                        help him escape his clutches.{" "}
                                    </div>
                                    <div style={{padding: "20px" ,paddingLeft: "0px"}}>
                                        <Space direction="vertical">
                                            <Space wrap size={15}>
                                                <Tooltip title="Read pdf" color="white" overlayInnerStyle={{color: "black"}}>
                                                    <Button
                                                        shape="circle"
                                                        icon={
                                                            <ReadOutlined />
                                                        }
                                                        onClick = {() => window.open('https://book-store.pages.dev/view/123', "_blank") }
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Download Pdf"  color="white" overlayInnerStyle={{color: "black"}}>
                                                    <Button
                                                        shape="circle"
                                                        icon={
                                                            <DownloadOutlined />
                                                        }
                                                        onClick = {()=> window.open('../src/components/Book/oops.pdf')}
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Wishlist"  color="white" overlayInnerStyle={{color: "black"}}>
                                                    <Button
                                                        shape="circle"
                                                        icon={
                                                            <PlusOutlined />
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip title="Share"  color="white" overlayInnerStyle={{color: "black"}}>
                                                    <Button
                                                        shape="circle"
                                                        icon={
                                                            <ShareAltOutlined />
                                                        }
                                                    />
                                                </Tooltip>
                                            </Space>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Card>
            <div style={{ display: "flex", justifyContent: "start" }}>
                <Title
                    
                    className="custom-title"
                    style={{
                        fontWeight: 400,
                        textAlign: "justify",
                        lineHeight: "30px",
                        color: "#000",
                        whiteSpace: "prewrap",
                    }}
                >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <span
                            class="shine"
                            style={{
                                fontSize: "40px",
                                lineHeight: "40px",
                            }}
                        >
                            {booksData && booksData.name}
                        </span>
                    </div>
                </Title>
            </div>
            <div>
                <Title
                    
                    className="custom-desc"
                    style={{
                        textAlign: "justify",
                        lineHeight: "30px",
                        minHeight: "120px",
                        whiteSpace: "prewrap",
                        display: "flex",
                        justifyContent: "center",
                        color: "#d2d2d2",
                    }}
                >
                    <span style={{ marginTop: "0px" }}>
                        {booksData && booksData.description}
                    </span>
                </Title>
            </div>
        </div>
    );
}
