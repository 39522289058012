import React, { useState, useEffect } from "react";
import {
    Button,
    Form,
    Input,
    Col,
    Row,
    Space,
    Select,
    DatePicker,
    Card,
} from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./style.css";

const options = [];
const genresValue = [
    "Literary Fiction",
    "Mystery",
    "Thriller",
    "Historical",
    "Romance",
    "Western",
    "Bildungsroman",
    "Speculative Fiction",
    "Science Fiction",
    "Fantasy",
    "Dystopian",
    "Magical Realism",
    "Realist Literature",
    "Horror",
];
for (let i = 0; i < 14; i++) {
    options.push({
        value: genresValue[i],
        label: genresValue[i],
    });
}

export default function UpdateBook() {
    const [form] = Form.useForm();
    const { bookId } = useParams();
    const { TextArea } = Input;

    const [data, setData] = useState({
        title: "Title",
        author: "",
        genres: "",
        price: "",
        description: "",
        available: true,
        imageUrl: "",
        publisher: "",
        publishDate: null,
        pageCount: "",
        language: "",
    });

    const handleSubmit = () => {
        let config = {
            method: "put",
            url: `https://zenith-shorthaired-nape.glitch.me/books/${bookId}`, //https://zenith-shorthaired-nape.glitch.me/books/${title.bookId} // http://localhost:5000/books/${title.bookId}
            headers: {},
            data: {
                author: data.author,
                available: data.available,
                description: data.description,
                genres: data.genres,
                image: data.imageUrl,
                language: data.language,
                // loves: "111k",
                name: data.title,
                page_count: data.pageCount,
                price: data.price,
                published_date: (data.publishDate).toString(),
                publisher: data.publisher,
                // views: "999k",
                // _id: "638cf410b4bd093b192b8825",
            },
        };

        axios(config)
            .then(async (response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        let config = {
            method: "get",
            url: `https://zenith-shorthaired-nape.glitch.me/books/${bookId}`, //https://zenith-shorthaired-nape.glitch.me/books/${title.bookId} // http://localhost:5000/books/${title.bookId}
            headers: {},
        };

        axios(config)
            .then(async (response) => {
                //console.log(response.data);
                await setData({
                    ...data,
                    title: response.data.book.name,
                    author: response.data.book.author,
                    genres: "",
                    price: response.data.book.price,
                    description: response.data.book.description,
                    available: true,
                    imageUrl: response.data.book.image,
                    publisher: response.data.book.publisher,
                    publishDate: new Date(response.data.book.published_date),
                    pageCount: response.data.book.page_count,
                    language: response.data.book.language,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);
    console.log(data);
    return (
        <>
            <Row align="center">
                {" "}
                <Card
                    hoverable
                    style={{ width: 250 }}
                    cover={<img alt="book cover" src={data.imageUrl} />}
                ></Card>
            </Row>
            <Row align="center">
                <Col xs={24} sm={8} offset={3} style={{ padding: "20px" }}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        style={{
                            maxWidth: 600,
                        }}
                        size="large"
                    >
                        <div>
                            <Form.Item label="Title of the Book">
                                <Input
                                    placeholder="The jungle book"
                                    value={data.title}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            title: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Genres">
                                <Select
                                    mode="tags"
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Tags Mode"
                                    value={[
                                        "Literary Fiction",
                                        "Mystery",
                                        "Thriller",
                                        "Historical",
                                        "Romance",
                                        "Western",
                                        "Bildungsroman",
                                        "Speculative Fiction",
                                        "Science Fiction",
                                        "Fantasy",
                                        "Dystopian",
                                        "Magical Realism",
                                        "Realist Literature",
                                        "Horror",
                                    ]}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            genres: [...data.genres, e],
                                        })
                                    }
                                    options={options}
                                />
                            </Form.Item>
                            <Form.Item label="Available">
                                <Select
                                    value={data.available}
                                    onChange={(e) =>
                                        setData({ ...data, available: e })
                                    }
                                    options={[
                                        {
                                            value: true,
                                            label: "Yes",
                                        },
                                        {
                                            value: false,
                                            label: "No",
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Image Url">
                                <Input
                                    placeholder="https://"
                                    value={data.imageUrl}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            imageUrl: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Space size={20}>
                                <Form.Item label="Publisher">
                                    <Input
                                        placeholder="Publisher Name"
                                        value={data.publisher}
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                publisher: e.target.value,
                                            })
                                        }
                                    />
                                </Form.Item>
                                {/* <Form.Item label="Published Date">
                                    <DatePicker
                                        mode = {"date"}
                                        size={"large"}
                                        value = {data.publishDate ? }
                                        onChange={(e) =>
                                            setData({
                                                ...data,
                                                publishDate: e._d.toString(),
                                            })
                                        }
                                    />
                                </Form.Item> */}
                            </Space>
                            <Form.Item label="Page Count">
                                <Input
                                    placeholder="148"
                                    value={data.pageCount}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            pageCount: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
                <Col xs={24} sm={8} offset={2} style={{ padding: "20px" }}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        style={{
                            maxWidth: 600,
                        }}
                        size="large"
                    >
                        <div>
                            <Form.Item label="Author of the Book">
                                <Input
                                    placeholder="J. K. Rowling"
                                    value={data.author}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            author: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Price">
                                <Input
                                    placeholder="2500"
                                    value={data.price}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            price: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="Description">
                                <TextArea
                                    rows={6}
                                    placeholder="Short Description"
                                    value={data.description}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            description: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>

                            <Form.Item label="Language">
                                <Input
                                    placeholder="English"
                                    value={data.language}
                                    onChange={(e) =>
                                        setData({
                                            ...data,
                                            language: e.target.value,
                                        })
                                    }
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
            </Row>
            <Form.Item style={{ paddingLeft: "60px", paddingTop: "0px" }}>
                <Button type="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Form.Item>
        </>
    );
}
