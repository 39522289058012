import React, { useState } from "react";
import { Document,Outline, Page } from "react-pdf";
import {LeftOutlined, RightOutlined, LoadingOutlined} from "@ant-design/icons";
import "./book.css";
import samplePDF from "./harry_potter.pdf";
import { pdfjs } from "react-pdf";
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function ViewPdf() {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Document
                file={samplePDF}
                options={{ workerSrc: "/pdf.worker.js" }}
                onSourceError={(err) => console.log(err)}
                onSourceSuccess={() => console.log("SUCCESS")}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={() => console.log("ERR")}
                className="parentContainer"
                loading = {<Spin indicator={antIcon} />}
            >
            <Outline />
                <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    className="parentContainer1"
                    loading = {<Spin indicator={antIcon} />}
                />
                <div class="page-controls">
                    <button
                        type="button"
                        disabled={pageNumber <= 1}
                        onClick={previousPage}
                    >
                        <LeftOutlined />
                    </button>
                    <span>
                        {pageNumber} of {numPages}
                    </span>
                    <button
                        type="button"
                        disabled={pageNumber >= numPages}
                        onClick={nextPage}
                    >
                        <RightOutlined />
                    </button>
                </div>
            </Document>
        </div>
    );
}
