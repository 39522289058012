import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableApp from "./Table/TableApp";
import { Button, Space, Tooltip } from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import axios from "axios";
import { Card } from "antd";

const { Meta } = Card;

function SearchAndEdit() {
    const navigate = useNavigate();
    const [val, setVal] = useState([]);

    useEffect(() => {
        // setLoading(true);
        let config = {
            method: "get",
            url: "https://zenith-shorthaired-nape.glitch.me/books", // https://zenith-shorthaired-nape.glitch.me/books // http://localhost:5000/books/
            headers: {},
        };

        axios(config)
            .then(async (response) => {
                let arr = [];
                await response.data.books.map((item, i) => {
                    arr.push({
                        key: i,
                        id: item._id,
                        title: item.name,
                        author: item.author,
                        price: item.price,
                        image: (
                            <Card
                                hoverable
                                style={{ width: 150 }}
                                cover={
                                    <img
                                        alt="example"
                                        src={item.image }
                                    />
                                }
                            >
                            </Card>
                        ),
                        action: (
                            <Space size="middle">
                                <Tooltip title="Edit">
                                    <Button
                                        shape="circle"
                                        icon={<EditTwoTone />}
                                        onClick={() => {
                                            navigate(
                                                "/admin/updateBook/" + item._id
                                            );
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <Button
                                        shape="circle"
                                        icon={<DeleteTwoTone />}
                                    />
                                </Tooltip>
                            </Space>
                        ),
                    });
                });
                await setVal(arr);
            })
            .catch((error) => {
                console.log(error);
                // setLoading(false);
            });
    }, []);

    return (
        <div>
            <TableApp data={val} />
        </div>
    );
}

export default SearchAndEdit;
