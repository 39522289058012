import React, { useState } from "react";
import { Button, Form, Input, Radio, Col, Row, Space, Select, DatePicker } from "antd";
import axios from "axios";
import "./style.css";

const options = [];
const genresValue = ["Literary Fiction", "Mystery", "Thriller", "Historical", "Romance", "Western", "Bildungsroman", "Speculative Fiction", "Science Fiction", "Fantasy", "Dystopian", "Magical Realism", "Realist Literature", "Horror"]
for (let i = 0; i < 14; i++) {
  options.push({
    value: genresValue[i],
    label: genresValue[i],
  });
}

function AddBook() {
    const [form] = Form.useForm();
    const { TextArea } = Input;


    const [data, setData] = useState({
        title: "",
        author: "",
        genres: [],
        price: "",
        description:"",
        available: true,
        imageUrl: "",
        publisher: "",
        publishDate: Date.now(),
        pageCount: "",
        language: "",
    })

    const handleSubmit = () => {
        console.log("Submit");
        let config = {
            method: "post",
            url: `https://zenith-shorthaired-nape.glitch.me/books/`, //https://zenith-shorthaired-nape.glitch.me/books/${title.bookId} // http://localhost:5000/books/${title.bookId}
            headers: {},
            data: {
                author: data.author,
                available: data.available,
                description: data.description,
                genres: "test",
                image: data.imageUrl,
                language: data.language,
                loves: "111k",
                name: data.title,
                page_count: data.pageCount,
                price: data.price,
                published_date: (data.publishDate).toString(),
                publisher: data.publisher,
                views: "999k",
                // _id: "638cf410b4bd093b192b8825",
            },
        };

        axios(config)
            .then(async (response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        
    }

    return (
        <>
            <Row align="center">
                <Col xs={24} sm={8} offset={3} style={{ padding: "20px" }}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        style={{
                            maxWidth: 600,
                        }}
                        size="large"
                    >
                        <div>
                            <Form.Item label="Title of the Book">
                                <Input placeholder="The jungle book" onChange= {(e) => setData({...data,title: e.target.value}) }/>
                            </Form.Item>
                            <Form.Item label="Genres">
                                <Select
                                    mode="tags"
                                    style={{
                                        width: "100%",
                                    }}
                                    placeholder="Tags Mode"
                                    onChange= {(e) => setData({...data,genres: [...data.genres,e]}) }
                                    options={options}
                                />
                            </Form.Item>
                            <Form.Item label="Available">
                                <Select
                                    defaultValue="yes"
                                    onChange = { (e) => setData({...data,available: e}) }
                                    options={[
                                        {
                                            value: true,
                                            label: "Yes",
                                        },
                                        {
                                            value: false,
                                            label: "No",
                                        },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item label="Image Url">
                                <Input placeholder="https://" onChange = { (e) => setData({...data,imageUrl: e.target.value}) }/>
                            </Form.Item>
                            <Space size={20}>
                            <Form.Item label="Publisher">
                                <Input placeholder="Publisher Name" onChange = { (e) => setData({...data,publisher: e.target.value}) }/>
                            </Form.Item>
                            <Form.Item label="Published Date">
                            <DatePicker size={"large"} onChange = { (e) => setData({...data,publishedDate: e}) }/>
                            </Form.Item>
                            </Space>
                            <Form.Item label="Page Count">
                                <Input placeholder="148" onChange = { (e) => setData({...data,pageCount: e.target.value}) }/>
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
                <Col xs={24} sm={8} offset={2} style={{ padding: "20px" }}>
                    <Form
                        layout={"vertical"}
                        form={form}
                        style={{
                            maxWidth: 600,
                        }}
                        size="large"
                    >
                        <div>
                            <Form.Item label="Author of the Book">
                                <Input placeholder="J. K. Rowling" onChange = { (e) => setData({...data,author: e.target.value})}/>
                            </Form.Item>
                            <Form.Item label="Price">
                                <Input placeholder="2500" onChange = { (e) => setData({...data,price: e.target.value})}/>
                            </Form.Item>
                            <Form.Item label="Description">
                                <TextArea
                                    rows={6}
                                    placeholder="Short Description"
                                    onChange = { (e) => setData({...data,description: e.target.value})}
                                />
                            </Form.Item>
                            
                            <Form.Item label="Language">
                                <Input placeholder="English" onChange = { (e) => setData({...data,language: e.target.value})}/>
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
            </Row>
            <Form.Item style={{ paddingLeft: "60px", paddingTop: "0px" }}>
                <Button type="primary" onClick={handleSubmit}>Submit</Button>
            </Form.Item>
        </>
    );
}

export default AddBook;
