import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Space, Drawer, Card } from "antd";

export default function ManageAdminDrawer({ visibility, onClose }) {


    return (
        <>
            <Drawer
                title="Manage"
                placement="left"
                onClose={onClose}
                visible={visibility}
            >
            <Space direction="vertical">
             <Link to="/admin/addBook"><Card title="Add a book" ></Card></Link>
             <Link to="/admin/searchEdit"><Card title="Update a book" /></Link>
             <Link to="/admin/users"><Card title="Users" /></Link>
             </Space>
            </Drawer>
        </>
    );
}

